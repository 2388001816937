<template>
   <div>
      <Users />
   </div>
</template>

<script>
import Users from "../components/Users/Users.vue";

export default {
   components: {
      Users,
   },
};
</script>