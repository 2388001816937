<template>
   <v-dialog v-model="dialog" max-width="600px">
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title>
            {{ title }}
         </v-card-title>
         <v-card-text>
            <form>
               <v-text-field v-model="username" label="Username" required :error-messages="usernameErrors"
                  @input="$v.username.$touch()" @blur="$v.username.$touch()" color="secondary"/>
               <v-text-field v-model="password" label="Contraseña" :error-messages="passwordErrors" :required="!isEdit"
                  :type="show_password ? 'text' : 'password'" :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show_password = !show_password" @input="$v.password.$touch()"
                  @blur="$v.password.$touch()" color="secondary"/>
            </form>
         </v-card-text>
         <v-card-actions class="justify-end pb-4">
            <v-btn @click="close" elevation="24" large>Cancelar</v-btn>
            <v-btn :loading="isloading" @click="save" elevation="24" large>Guardar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { required, maxLength, minLength, alphaNum } from "vuelidate/lib/validators";

export default {
   props: {
      isEdit: Boolean,
   },
   validations: {
      username: {
         alphaNum,
         required,
         maxLength: maxLength(255),
         minLength: minLength(5),
      },
      password: {
         required: function () {
            if (this.isEdit) {
               return true;
            } else if (this.password.trim().length > 0) {
               return true;
            } else {
               return false;
            }
         },
         minLength: minLength(5),
         maxLength: maxLength(255),
      },
   },
   data: function () {
      return {
         usuario_id: null,
         username: "",
         password: "",

         dialog: false,
         show_password: false,
         isloading: false,
      };
   },
   watch: {
      dialog: function (val) {
         if (!val) {
            this.close();
            this.$emit("close");
         }
      },
   },
   methods: {
      save: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;
               if (!this.isEdit) {
                  res = await this.$store.dispatch("insertUsuario", {
                     username: this.username,
                     password: this.password,
                  });
               } else {
                  res = await this.$store.dispatch("updateUsuario", {
                     usuario_id: this.usuario_id,
                     username: this.username,
                     password: this.password,
                  });
               }
               if (res == true) {
                  this.$emit("save");
                  this.close();
               } else {
                  throw res;
               }
            } else {
               throw "Validacion";
            }
         } catch (error) {
            this.$refs.error_noti.Show(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
      },
      show: function (i) {
         this.dialog = true;
         this.isloading = false;
         this.usuario_id = i.usuario_id;
         this.username = i.username;
         this.password = "";
      },
   },
   computed: {
      title() {
         if (this.isEdit) {
            return "Editar Usuario";
         } else {
            return "Crear Usuario";
         }
      },
      usernameErrors() {
         const errors = [];
         if (!this.$v.username.$dirty) return errors;
         !this.$v.username.required && errors.push("El username es requerido");
         !this.$v.username.maxLength && errors.push("Debe contener maximo 255 caracteres");
         !this.$v.username.minLength && errors.push("Debe contener minimo 5 caracteres");
         !this.$v.username.alphaNum && errors.push("Debe contener caracteres alfanumericos");
         return errors;
      },
      passwordErrors() {
         const errors = [];
         if (!this.$v.password.$dirty) return errors;
         !this.$v.password.required && errors.push("La contraseña es requerida");
         !this.$v.password.minLength && errors.push("Debe contener minimo 5 caracteres");
         !this.$v.password.maxLength && errors.push("Debe contener maximo 255 caracteres");
         return errors;
      },
   },
};
</script>
