<template>
   <div class="ma-xs-1 ma-md-4 mb-16">
      <UserForm @save="$refs.ok_noti.Show()" :isEdit="false" ref="user" />
      <UserForm @save="$refs.ok_noti.Show()" :isEdit="true" ref="user_edit" />
      <Dialog message="¿Desea eliminar el usuario?" @result_ok="confirmDeleteItem()" ref="deleteItem" />
      <Notification type="error" message="Error" ref="error_noti" />
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <v-data-table :loading="isloading" :headers="headers" :items="$store.state.users" class="elevation-1">
         <template v-slot:top>
            <v-toolbar flat>
               <v-btn color="secondary" class="custom-button" @click="newItem()">
                  <span class="custom-text">
                     <v-icon left>mdi-plus-circle</v-icon>Nuevo usuario
                  </span>
               </v-btn>
               <v-btn @click="initialize" icon small class="ml-2"><v-icon small>mdi-autorenew</v-icon></v-btn>
               <v-spacer></v-spacer>
            </v-toolbar>
         </template>
         <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import UserForm from "./UserForm.vue";

export default {
   components: {
      UserForm,
   },
   data() {
      return {
         headers: [
            { text: "ID", value: "usuario_id" },
            { text: "Username", value: "username" },
            { text: "Acciones", value: "actions" },
         ],
         itemToDelete: null,
         isloading: true,
      };
   },
   methods: {
      async initialize() {
         try {
            this.isloading = true;
            await this.$store.dispatch("getUsuarios");
         } catch (error) {
            this.$refs.error_noti.Show(error);
         } finally {
            this.isloading = false;
         }
      },
      async newItem() {
         this.$refs.user.show({
            usuario_id: null,
            username: "",
            password: "",
         });
      },
      async editItem(item) {
         this.$refs.user_edit.show({
            usuario_id: item.usuario_id,
            username: item.username,
            password: "",
         });
      },
      async deleteItem(item) {
         this.itemToDelete = item;
         this.$refs.deleteItem.Show();
      },
      async confirmDeleteItem() {
         try {
            this.isloading = true;
            let res = null;
            res = await this.$store.dispatch("deleteUsuario", {
               usuario_id: this.itemToDelete.usuario_id,
            });
            if (res == true) {
               this.$refs.ok_noti.Show();
            } else {
               throw res;
            }
         } catch (error) {
            this.$refs.error_noti.Show(error);
         } finally {
            this.isloading = false;
         }
      },
   },
   created() {
      this.initialize();
   },
};
</script>